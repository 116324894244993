<script>
import animationData from "@/components/widgets/nkmsrxys.json";
import Lottie from "@/components/widgets/lottie.vue";

export default {
  data() {
    return {
      defaultOptions: { animationData: animationData },
    };
  },
  components: {lottie: Lottie}
}
</script>

<template>
  <!-- auth-page wrapper -->
  <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5">
            <div class="card overflow-hidden">
              <div class="card-body p-4">
                <div class="text-center">
                  <lottie colors="primary:#405189,secondary:#0ab39c" :options="defaultOptions"/>
                  <h1 class="text-primary mb-4">Oops !</h1>
                  <h4 class="text-uppercase">Désolé, la page n'existe pas 😭</h4>
                  <p class="text-muted mb-4">La page que vous démandez n'existe pas</p>
                  <router-link to="/" class="btn btn-success"><i class="mdi mdi-home me-1"></i>Back to home</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>